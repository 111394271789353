// src/compo/Sidebar.js

import React from 'react';
import { Box, List, ListItem, ListItemIcon, ListItemText, Divider, Typography } from '@mui/material';
import {
  Dashboard,
  Store,
  Newspaper,
  Event,
  Article,
  ContactMail,
  Home, // New icon for Home
  LocalMall, // New icon for Vendor Management
  Feed, // New icon for News
  EventAvailable, // New icon for Events
  FactCheck, // New icon for Event Registration
  Chat, // New icon for Blogs
  ContactPage, // New icon for Contact Us
} from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import LogoImage from './img/CONNEX.png';
import CopyrightLogo from './img/image.png'; // Import your company logo

const themeColors = {
  sidebarBg: 'linear-gradient(to bottom, #2e2e2e, #444444)',
  buttonBg: '#444444',
  buttonText: '#ffffff',
  hoverBg: '#555555',
  borderColor: '#222222',
  logoBg: '#2e2e2e',
  footerBg: '#333333',
};

const SidebarContainer = styled(Box)(({ theme }) => ({
  width: '200px',
  height: '100vh',
  background: themeColors.sidebarBg,
  color: themeColors.buttonText,
  display: 'flex',
  flexDirection: 'column',
  boxShadow: theme.shadows[3],
  borderRight: `1px solid ${themeColors.borderColor}`,
  overflowY: 'auto',
  position: 'fixed',
  top: 0,
  left: 0,
  margin: 0,
  padding: 0,
  zIndex: 1200,
  transition: 'transform 0.3s ease-in-out',
  [theme.breakpoints.down('sm')]: {
    transform: 'translateX(-100%)',
    '&.active': {
      transform: 'translateX(0)',
    },
  },
}));

const LogoContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: themeColors.logoBg,
  borderBottom: `1px solid ${themeColors.borderColor}`,
  margin: 0,
}));

const LogoImageStyled = styled('img')({
  height: '85px',
  width: 'auto',
  transition: 'transform 0.2s',
  '&:hover': {
    transform: 'scale(1.1)',
  },
});

const StyledListItem = styled(ListItem)(({ theme }) => ({
  borderRadius: '4px',
  width: '90%',
  margin: '5px auto',
  padding: '5px 12px',
  height: '35px',
  alignItems: 'center',
  backgroundColor: themeColors.buttonBg,
  color: themeColors.buttonText,
  '&:hover': {
    backgroundColor: themeColors.hoverBg,
    transform: 'scale(1.03)',
    boxShadow: theme.shadows[2],
  },
  '& .MuiListItemIcon-root': {
    minWidth: '35px',
    color: themeColors.buttonText,
    marginRight: '8px',
  },
  '& .MuiListItemText-primary': {
    color: themeColors.buttonText,
    fontSize: '14px',
    fontWeight: 400,
    fontFamily: "'Roboto', sans-serif",
  },
}));

const CopyrightContainer = styled(Box)(({ theme }) => ({
  marginTop: 'auto',
  padding: theme.spacing(2),
  textAlign: 'center',
  backgroundColor: themeColors.footerBg,
  borderTop: `1px solid ${themeColors.borderColor}`,
  color: themeColors.buttonText,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const CopyrightLogoStyled = styled('img')({
  height: '80px',
  width: 'auto',
  opacity: '0.75',
  borderRadius: '10px',
  transition: 'opacity 0.3s',
  '&:hover': {
    opacity: '1',
  },
});

const navItems = [
  { text: 'Home', icon: <Home />, path: '/dashboard' },  
  { text: 'Vendor', icon: <LocalMall />, path: '/vendormgt' },      
  { text: 'News', icon: <Feed />, path: '/news' },         
  { text: 'Events', icon: <EventAvailable />, path: '/events' },         
  { text: 'Event Reg:', icon: <FactCheck />, path: '/eventreg' },
  { text: 'Blogs', icon: <Chat />, path: '/blog' },
  { text: 'Contact Us', icon: <ContactPage />, path: '/contact' },
];

const Sidebar = () => (
  <SidebarContainer>
    <LogoContainer>
      <LogoImageStyled src={LogoImage} alt="Logo" />
    </LogoContainer>
    <Divider sx={{ bgcolor: themeColors.borderColor }} />
    <Typography
      variant="subtitle1"
      sx={{
        textAlign: 'center',
        fontWeight: 500,
        color: themeColors.buttonText,
        py: 1,
        fontSize: '13px',
        fontFamily: "'Roboto', sans-serif",
      }}
    >
      Service
    </Typography>
    <Divider sx={{ bgcolor: themeColors.borderColor }} />
    <List>
      {navItems.map((item, index) => (
        <StyledListItem
          button
          component={item.path ? Link : 'div'}
          to={item.path || undefined}
          key={index}
        >
          <ListItemIcon>{item.icon}</ListItemIcon>
          <ListItemText primary={item.text} />
        </StyledListItem>
      ))}
    </List>
    <Divider sx={{ bgcolor: themeColors.borderColor }} />
    <CopyrightContainer>
      <CopyrightLogoStyled src={CopyrightLogo} alt="Company Logo" />
    </CopyrightContainer>
  </SidebarContainer>
);

export default Sidebar;
