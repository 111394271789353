import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Box,
    Typography,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Zoom,
    MenuItem,
    Select,
    InputLabel,
    FormControl,
    IconButton,
    CircularProgress,
} from '@mui/material';
import { styled } from '@mui/system';
import { Print, Search } from '@mui/icons-material';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Swal from 'sweetalert2';
import { apilinkmain } from '../api';

const themeColor = {
    primary: '#444',
    primaryDark: '#666',
    success: '#4caf50',
    error: '#f44336',
    headerBg: '#444',
    headerTextColor: '#ffffff',
    borderColor: '#777',
    color: '#000000',
    rowHoverColor: '#ebebeb',
    rowAlternateColor: '#f5f5f5',
    rowHoverHighlight: '#e0f7fa',
  };
  
  // Styled components
  const PremiumButton = styled(Button)(({ variant }) => ({
    borderRadius: '6px',
    padding: '6px 12px',
    textTransform: 'none',
    fontWeight: 'bold',
    height: '30px',
    minWidth: '80px',
    fontSize: '12px',
    transition: 'background-color 0.3s ease, transform 0.3s ease',
    cursor: 'pointer',
    border: 'none',
    boxShadow: '0 3px 6px rgba(0, 0, 0, 0.1)',
    background: variant === 'contained' ? themeColor.primary : 'transparent',
    color: variant === 'contained' ? '#fff' : themeColor.primary,
    border: variant === 'outlined' ? `1px solid ${themeColor.primary}` : 'none',
    '&:hover': {
      backgroundColor: variant === 'contained' ? themeColor.primaryDark : 'rgba(51, 51, 51, 0.05)',
      transform: 'scale(1.03)',
    },
    '&:active': {
      transform: 'scale(0.97)',
    },
  }));
  
  const StyledTableCell = styled(TableCell)({
    fontSize: '12px',
    borderBottom: `1px solid ${themeColor.borderColor}`,
    padding: '6px 8px',
    textAlign: 'center',
    color: themeColor.color,
  });
  
  const StyledTableRow = styled(TableRow)({
    cursor: 'pointer',
    height: '40px',
    transition: 'background-color 0.3s ease',
    '&:nth-of-type(odd)': {
      backgroundColor: '#ffffff',
    },
    '&:hover': {
      backgroundColor: themeColor.rowHoverHighlight,
      boxShadow: '0px 2px 4px rgba(0,0,0,0.05)',
    },
  });
  
  const StyledTableHead = styled(TableHead)({
    backgroundColor: themeColor.primary,
    color: themeColor.headerTextColor,
    position: 'sticky',
    top: 0,
    zIndex: 2,
    '& th': {
      fontSize: '13px',
      fontWeight: 'bold',
      padding: '10px 12px',
      textAlign: 'center',
      borderRight: `1px solid ${themeColor.borderColor}`,
      background: themeColor.primary,
      color: themeColor.headerTextColor,
      '&:last-child': {
        borderRight: 'none',
      },
    },
  });
  
  const StyledTextField = styled(TextField)({
    '& .MuiInputBase-root': {
      fontSize: '14px',
    },
  });
  
  const StyledTextArea = styled(TextField)({
    '& .MuiInputBase-root': {
      fontSize: '14px',
    },
  });
  
  const DetailImage = styled('img')({
    width: '70%',
    height: 'auto',
    borderRadius: '8px',
    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)',
    marginBottom: '16px',
  });

const EventRegister = () => {
    const [events, setEvents] = useState([]);
    const [registrations, setRegistrations] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [showDetailPopup, setShowDetailPopup] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [filterEvent, setFilterEvent] = useState('');
    const [loading, setLoading] = useState(false);

    const fetchEvents = async () => {
        setLoading(true); // Start loading
        try {
            const cnt = localStorage.getItem('cnt');
            const response = await axios.get(`${apilinkmain}/events`, {
                withCredentials: true,
                headers: { cnt: cnt },
            });
            const events = response.data;
            setEvents(events);
        } catch (error) {
            console.error('Error fetching events:', error);
            Swal.fire('Error', 'Failed to fetch events data.', 'error');
        } finally {
            setLoading(false); // Stop loading
        }
    };

    const fetchEventRegistrations = async (eventId) => {
        setLoading(true); // Start loading
        try {
            const response = await axios.get(`${apilinkmain}/events/${eventId}/registrations`, {
                withCredentials: true,
            });
            setRegistrations(response.data);
        } catch (error) {
            console.error('Error fetching registrations:', error);
            Swal.fire('Error', 'Failed to fetch registrations data.', 'error');
        } finally {
            setLoading(false); // Stop loading
        }
    };

    useEffect(() => {
        fetchEvents(); // Fetch events when the component mounts
    }, []);

    useEffect(() => {
        if (filterEvent) {
            fetchEventRegistrations(filterEvent);
        }
    }, [filterEvent]);

    const handleRowClick = (user) => {
        setSelectedUser(user);
        setShowDetailPopup(true);
    };

    const handleCloseDetailPopup = () => {
        setShowDetailPopup(false);
        setSelectedUser(null);
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleEventChange = (e) => {
        setFilterEvent(e.target.value);
    };

    const filteredData = registrations.filter((user) => {
        const normalizedSearchTerm = searchTerm.toLowerCase().trim();

        return (
            (user.name?.toLowerCase().includes(normalizedSearchTerm) ||
                user.companyName?.toLowerCase().includes(normalizedSearchTerm) ||
                user.email?.toLowerCase().includes(normalizedSearchTerm))
        );
    });

    const exportToPDF = () => {
        const eventTitle = events.find(event => event.id === filterEvent)?.title || 'Event';
        const doc = new jsPDF('landscape');
        doc.text(`${eventTitle} Register Information Sheet`, 20, 10);
        doc.autoTable({
            head: [['NO', 'Title', 'Name', 'Designation', 'Company Name', 'Email', 'Contact No', 'Province', 'City','Status']],
            body: filteredData.map((user, index) => [
                index + 1,
                user.title,
                user.name,
                user.designation,
                user.companyname,
                user.email,
                user.contactno,
                user.province,
                user.city,
                user.status,
            ]),
        });
        doc.save(`${eventTitle}_Registration_Information.pdf`);
        Swal.fire('Success', 'PDF downloaded successfully!', 'success');
    };

    const exportToExcel = () => {
        const eventTitle = events.find(event => event.id === filterEvent)?.title || 'Event';
        const worksheet = XLSX.utils.json_to_sheet(
            filteredData.map((user, index) => ({
                NO: index + 1,
                Title: user.title,
                Name: user.name,
                Designation: user.designation,
                'Company Name': user.companyname,
                Email: user.email,
                'Contact No': user.contactno,
                Province: user.province,
                City: user.city,
            }))
        );
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Event Register');
        XLSX.writeFile(workbook, `${eventTitle}_Registration_Information.xlsx`);
        Swal.fire('Success', 'Excel file downloaded successfully!', 'success');
    };

    return (
        <Box sx={{ padding: 2, overflowY: 'hidden' }}>
            <Typography variant="h5">Event Register</Typography>

            {/* Search, Filter, and Action Buttons */}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', width: '40%', marginLeft: '10px', marginTop: '10px' }}>
                    <TextField
                        label="Search by Name, Company Name, or Email"
                        variant="outlined"
                        size="small"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        sx={{ flexGrow: 1 }}
                        InputProps={{
                            startAdornment: (
                                <IconButton>
                                    <Search />
                                </IconButton>
                            ),
                        }}
                    />
                </Box>
                <FormControl variant="outlined" size="small" sx={{ width: '25%', marginTop: '10px' }}>
                    <InputLabel>Event</InputLabel>
                    <Select
                        value={filterEvent}
                        onChange={handleEventChange}
                        label="Event"
                    >
                        <MenuItem value="">
                            <em>All Events</em>
                        </MenuItem>
                        {events.map((event) => (
                            <MenuItem key={event.id} value={event.id}>
                                {event.title}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Box sx={{ display: 'flex', alignItems: 'center', width: '25%', justifyContent: 'flex-end' }}>
                    <PremiumButton variant="contained" onClick={exportToPDF} sx={{ marginRight: 1 }}>
                        <Print /> PDF
                    </PremiumButton>
                    <PremiumButton variant="outlined" onClick={exportToExcel}>
                        Export Excel
                    </PremiumButton>
                </Box>
            </Box>

            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
                    <CircularProgress />
                </Box>
            ) : (
                <TableContainer
                    component={Paper}
                    sx={{
                        overflow: 'auto',
                        height: '500px',
                        marginBottom: '20px',
                        borderRadius: '8px',
                        boxShadow: '0 3px 6px rgba(0,0,0,0.1)',
                    }}
                >
                    <Table stickyHeader>
                        <StyledTableHead>
                            <TableRow>
                                <StyledTableCell>NO</StyledTableCell>
                                <StyledTableCell>Title</StyledTableCell>
                                <StyledTableCell>Name</StyledTableCell>
                                <StyledTableCell>Designation</StyledTableCell>
                                <StyledTableCell>Company Name</StyledTableCell>
                                <StyledTableCell>Email</StyledTableCell>
                                <StyledTableCell>Contact No</StyledTableCell>
                                <StyledTableCell>Province</StyledTableCell>
                                <StyledTableCell>City</StyledTableCell>
                                <StyledTableCell>Status</StyledTableCell>
                            </TableRow>
                        </StyledTableHead>
                        <TableBody>
                            {filteredData.map((user, index) => (
                                <StyledTableRow key={user.id} onClick={() => handleRowClick(user)}>
                                    <StyledTableCell>{user.id}</StyledTableCell>
                                    <StyledTableCell>{user.title}</StyledTableCell>
                                    <StyledTableCell>{user.name}</StyledTableCell>
                                    <StyledTableCell>{user.designation}</StyledTableCell>
                                    <StyledTableCell>{user.companyname}</StyledTableCell>
                                    <StyledTableCell>{user.email}</StyledTableCell>
                                    <StyledTableCell>{user.contactno}</StyledTableCell>
                                    <StyledTableCell>{user.province}</StyledTableCell>
                                    <StyledTableCell>{user.city}</StyledTableCell>
                                    <StyledTableCell>{user.status}</StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}

            {/* Detail Popup */}
            <Dialog
                open={showDetailPopup}
                onClose={handleCloseDetailPopup}
                TransitionComponent={Zoom}
                transitionDuration={300}
                maxWidth="sm"
                fullWidth
            >
                <Typography sx={{padding: '10px', fontWeight:'bold'}}>Event Registration Details</Typography>
                <DialogContent dividers>
                    {selectedUser && (
                        <Box>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <StyledTableCell sx={{textAlign: 'left', fontWeight:'bold'}}>Title:</StyledTableCell>
                                        <StyledTableCell sx={{textAlign: 'left',}}>{selectedUser.title}</StyledTableCell>
                                    </TableRow>
                                    <TableRow>
                                        <StyledTableCell sx={{textAlign: 'left', fontWeight:'bold'}}>Name:</StyledTableCell>
                                        <StyledTableCell sx={{textAlign: 'left',}}>{selectedUser.name}</StyledTableCell>
                                    </TableRow>
                                    <TableRow>
                                        <StyledTableCell sx={{textAlign: 'left', fontWeight:'bold'}}>Designation:</StyledTableCell>
                                        <StyledTableCell sx={{textAlign: 'left',}}>{selectedUser.designation}</StyledTableCell>
                                    </TableRow>
                                    <TableRow>
                                        <StyledTableCell sx={{textAlign: 'left', fontWeight:'bold'}}>Company Name:</StyledTableCell>
                                        <StyledTableCell sx={{textAlign: 'left',}}>{selectedUser.companyname}</StyledTableCell>
                                    </TableRow>
                                    <TableRow>
                                        <StyledTableCell sx={{textAlign: 'left', fontWeight:'bold'}}>Email:</StyledTableCell>
                                        <StyledTableCell sx={{textAlign: 'left',}}>{selectedUser.email}</StyledTableCell>
                                    </TableRow>
                                    <TableRow>
                                        <StyledTableCell sx={{textAlign: 'left', fontWeight:'bold'}}>Contact No:</StyledTableCell>
                                        <StyledTableCell sx={{textAlign: 'left',}}>{selectedUser.contactno}</StyledTableCell>
                                    </TableRow>
                                    <TableRow>
                                        <StyledTableCell sx={{textAlign: 'left', fontWeight:'bold'}}>Province:</StyledTableCell>
                                        <StyledTableCell sx={{textAlign: 'left',}}>{selectedUser.province}</StyledTableCell>
                                    </TableRow>
                                    <TableRow>
                                        <StyledTableCell sx={{textAlign: 'left', fontWeight:'bold'}}>City:</StyledTableCell>
                                        <StyledTableCell sx={{textAlign: 'left',}}>{selectedUser.city}</StyledTableCell>
                                    </TableRow>
                                    <TableRow>
                                        <StyledTableCell sx={{textAlign: 'left', fontWeight:'bold'}}>Status:</StyledTableCell>
                                        <StyledTableCell sx={{textAlign: 'left',}}>{selectedUser.status}</StyledTableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>
                    )}
                </DialogContent>
                <DialogActions>
                    <PremiumButton onClick={handleCloseDetailPopup} variant="outlined">
                        Close
                    </PremiumButton>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default EventRegister;
